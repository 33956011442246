.MiniTile{
  border-radius: 3px;
  background: #F6F4ED;
}

.MiniTile--blue{
  background: #72B3FF;
}

.MiniTile--red{
  background: #FF7272;
}

.MiniTile--black{
  background: #000000;
}

.MiniTile--innocent{
  background: #F1E6CB;
}
