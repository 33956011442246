.Tile{
  border: 10px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #F6F4ED;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: transform 1s ease, background-color 0.1s 0.3s, box-shadow 0.2s;
  transform-style: preserve-3d;
  cursor: pointer;
}

.Tile:hover{
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
}

.Tile span{
  transition: opacity 0.1s 0.25s;
}

.Tile--blue{
  background: #72B3FF;
  transform: rotateX(180deg);
}

.Tile--red{
  background: #FF7272;
  transform: rotateX(180deg);
}

.Tile--black{
  background: #000000;
  transform: rotateX(180deg);
}

.Tile--innocent{
  background: #F1E6CB;
  transform: rotateX(180deg);
}

.Tile--flipped{
  box-shadow: none;
  cursor: default;
}

.Tile--flipped:hover{
  box-shadow: none;
}

.Tile--flipped span{
  opacity: 0;
}
