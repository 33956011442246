.Grid{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  height:80vh;
  padding:30px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  box-sizing: border-box;
  flex: 5;
}

.table{
  display: flex;
  height:100vh;
  flex-direction: column;
  justify-content: space-between;
}

.Map{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  width:100vw;
  height:100vw;
  padding:30px;
  box-sizing: border-box;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  visibility: hidden;
  display: none;
}

.footer{
  padding: 30px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  background: #000000;
  box-sizing: border-box;
  color: white;
}

.footer span{
  background: white;
  border-radius: 3px;
  padding: 5px 10px;
}

.red-tally{
  color: #FF7272;
  font-weight: bold;
}

.blue-tally{
  color: #72B3FF;
  font-weight: bold;
}

.new-board{
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .Map {
    visibility: visible;
    display: grid;
  }
  .Grid{
    display: none;
    height:100vw;
  }
  .footer{
    position: fixed;
    bottom:0px;
    left:0px;
    right:0px;
  }
}
